@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    display: inline-grid;
    margin-bottom: var(--spacing-core-4);
}

.skeletonFilter {
    height: var(--sizing-core-10);
    margin: var(--spacing-core-1) 0;
    border-radius: var(--border-radius-core-circle);
}

.list {
    display: flex;
    flex-wrap: nowrap;
    column-gap: var(--spacing-core-2);
    margin: 0;
    padding: 0;
    list-style: none;
    overflow-x: auto;
    white-space: nowrap;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    will-change: scroll-position;

    &::-webkit-scrollbar {
        display: none;
    }

    @include mq.mq($from: large) {
        flex-wrap: wrap;
    }
}

.filterReset {
    border: 1px solid var(--color-background-inverted-default);
    transition: border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover {
        border: 1px solid var(--color-background-secondary-hover);
    }
}
